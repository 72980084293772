export function copyEmailToClipboard() {
    const emailElement = document.getElementById("email");
  
    if (emailElement) {
      const emailText = emailElement.innerText;
      const textArea = document.createElement("textarea");
  
      // Copie o texto para a área de transferência
      textArea.value = emailText;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      emailElement.classList.add("email-selected");
  
      // Remova a classe após um curto período para reverter o efeito
      setTimeout(() => {
        emailElement.classList.remove("email-selected");
      }, 500); // Ajuste o tempo conforme necessário
    }
  }
  