import React, { useState, useEffect } from 'react';
import './App.css';
import Grid from '@mui/material/Unstable_Grid2';
import Cv from './cv';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import Link from '@mui/material/Link';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import Pokedex from './pokedex.jpg'
import UpperText from './UpperText.jpeg'
import MaterialUISwitch from './materialUISwitch';
import { copyEmailToClipboard } from './copyEmail';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import AGI from './agi.png'

function App() {

  useEffect(() => {
    const experiencesContainers = document.querySelectorAll('.experiences');
    const desc = document.querySelector('.desc');
    const readIcon = document.getElementById('readIcon');

    // Crie funções separadas para os ouvintes de eventos
    const handleMouseEnter = () => {
      readIcon.style.opacity = '1';
    };

    const handleMouseLeave = () => {
      readIcon.style.opacity = '0';
    };

    // Itere pelos elementos experiencesContainers e adicione ouvintes de eventos
    experiencesContainers.forEach((experiencesContainer) => {
      experiencesContainer.addEventListener('mouseenter', handleMouseEnter);
      experiencesContainer.addEventListener('mouseleave', handleMouseLeave);
    });
    desc.addEventListener('mouseenter', handleMouseEnter)
    desc.addEventListener('mouseleave', handleMouseLeave)

    // Remova os ouvintes de eventos ao desmontar o componente
    return () => {
      experiencesContainers.forEach((experiencesContainer) => {
        experiencesContainer.removeEventListener('mouseenter', handleMouseEnter);
        experiencesContainer.removeEventListener('mouseleave', handleMouseLeave);
        desc.addEventListener('mouseenter', handleMouseEnter)
        desc.addEventListener('mouseleave', handleMouseLeave)

      });
    };
  }, []); // Certifique-se de que esta função só é executada uma vez após a montagem do componente


  document.addEventListener("mousemove", (e) => {
    const customCursor = document.getElementById("custom-cursor");
    customCursor.style.left = `${e.clientX}px`;
    customCursor.style.top = `${e.clientY}px`;
  });

  const [darkMode, setDarkMode] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  // Função para alternar entre dark mode e light mode
  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    // Salva a preferência do usuário no localStorage
    localStorage.setItem('darkMode', newMode.toString());
  };

  useEffect(() => {
    document.body.classList.toggle('dark-mode', darkMode);
  }, [darkMode]);

  const experiences = [
    {
      date: 'fev - dez 2022 ',
      title: 'Front-end Developer',
      position: 'Freelance',
      description: 'Desenvolvimento das telas elaboradas anteriormente pelo cliente no figma, utilizando React/Material-UI e Node.js',
      labels: ['React', 'Material-UI', 'Node.js'],
    },
    {
      date: 'jun - jul  2022',
      title: 'Loop consultoria',
      position: 'Freelance',
      description: 'Atualização do Front-end da página de cadastros da empresa destinada de maneira intuitiva e responsiva.',
      labels: ['CSS', 'HTML', 'JavaScript'],
    },
    {
      date: 'jun - set  2023 ',
      title: 'SPTrans',
      position: 'Freelance',
      description: 'Sistema web para automatização de processos internos utilizando Python/Django. Fui o responsável por criar e desenvolver do zero todo o Front-end do sistema de maneira responsiva e intuitiva criando os layouts no FIGMA e os desenvolvendo posteriormente em HTML, CSS, JavaScript.',
      labels: ['CSS', 'Python', 'Django', 'JavaScript'],
    },
  ];
  return (
    <Grid container spacing={2}>
      <Grid xs={2} id='grid'>
        <MaterialUISwitch sx={{ m: 1 }} checked={darkMode} onChange={toggleDarkMode} />
      </Grid>
      <Grid xs={8} id='gridPrincipal'>

        <div id="custom-cursor"><AutoStoriesOutlinedIcon id='readIcon' /></div>

        <div className='containerPrincipal' >
          <div className='eu'>
            <div className='myInfos'>
              <h1>Adriano Vicentini</h1>
              <h3>Front-end Developer</h3>
              <p className='titulo'>O desafio de tornar a web mais amigável e envolvente é o que me inspira diariamente.</p>
              <p className='desc'>Comecei minha jornada na programação durante a pandemia de 2020, como um curioso, e desde então, essa paixão tem moldado minha vida. <br></br><br></br>Sou um desenvolvedor front-end com um sólido conhecimento em JavaScript, CSS e React. Tive a oportunidade de colaborar em diversos projetos como freelancer, onde apliquei meu conhecimento técnico para criar interfaces intuitivas e responsivas.<br></br><br></br>Atualmente, estou cursando Análise e Desenvolvimento de Sistemas na UNINTER, no 2º semestre do curso. Durante minha jornada acadêmica, adquiri uma base sólida em fundamentos como lógica, algoritmos, matemática computacional e programação orientada a objetos.
                <br></br><br></br>Quando não estou codando na base do café, eu toco violão, finjo que tenho uma banda com meu irmão mais novo e passo raiva em jogos competitivos.
              </p>

            </div>
            <div className='socials'>
              <Link href="https://www.linkedin.com/in/vicentiniadriano/" target="_blank" color="inherit" rel="noopener noreferrer"><LinkedInIcon className='icons' /></Link>
              <Link href="https://github.com/For-otten" target="_blank" color="inherit" rel="noopener noreferrer"><GitHubIcon className='icons' /></Link>
              <Link href="https://twitter.com/For_otten" target="_blank" color="inherit" rel="noopener noreferrer"><TwitterIcon className='icons' /></Link>
              <p id="email" className='email' onClick={copyEmailToClipboard}>dev.vicentini@outlook.com <ContentCopyIcon fontSize='small' className='copy' /></p>
            </div>
          </div>
          <div className='conteudo'>
            {experiences.map((experience, index) => (
              <Cv
                key={index}
                date={experience.date}
                title={experience.title}
                position={experience.position}
                description={experience.description}
                labels={experience.labels}
              />
            ))}
            <Link href="https://drive.google.com/file/d/1qlDoj-itZcyH1Zk-xgtZZMhOcdaiJ562/view?usp=sharing" target="_blank" rel="noopener noreferrer" color="inherit" underline="none" className='viewCv' fontSize={'14px'}><p>Visualizar Currículo Completo</p> <OpenInNewOutlinedIcon className='cvIcon' fontSize='small' /></Link>
            <div className='portfolios'>
              <Link href='https://for-otten.github.io/Sistema-SPTRANS/index.html' className='portfolio' target="_blank" color="inherit" underline="none">
                <img src={AGI} className='imgProject' alt='Imagem ilustrativa do projeto feito para SPTrans' />
                <div className='descPortfolio'>
                  <h3>A.G.I <OpenInNewOutlinedIcon fontSize='small' className='portfolioIcon' /></h3>
                  <p>Um dos projetos mais significativos do meu portfolio. Neste sistema interno da <strong>SPTrans.</strong><br></br>Desempenhei um papel central no front-end.<br></br> Desde a <strong>concepção do layout</strong> até a sua implementação, fui o responsável por tornar a experiência do usuário mais <srtong>amigável e envolvente.</srtong></p>
                </div>
              </Link>
              <Link href='https://for-otten.github.io/Pokedex-DDevs/index/' className='portfolio' target="_blank" color="inherit" underline="none">
                <img src={Pokedex} className='imgProject' alt='Imagem ilustrativa do projeto Pokedex' />
                <div className='descPortfolio'>
                  <h3>Pokedex <OpenInNewOutlinedIcon fontSize='small' className='portfolioIcon' /></h3>
                  <p>Minha Pokedex é onde eu treinei minhas habilidades em <strong>consumir APIs</strong>. É como um guia interativo para os fãs de Pokémon. Explore o mundo dos Pokémon e descubra suas informações favoritas. Uma jornada divertida de aprendizado em <strong>JavaScript.</strong></p>
                </div>
              </Link>
              <Link href='https://chrome.google.com/webstore/detail/upper-text/meelgfdngdjhigfhkdaknhmoljcmdcbd?hl=pt-BR' className='portfolio' target="_blank" color="inherit" underline="none">
                <img src={UpperText} className='imgProject' alt='Imagem ilustrativa da extensão chrome UpperText' />
                <div className='descPortfolio'>
                  <h3>UpperText <OpenInNewOutlinedIcon fontSize='small' className='portfolioIcon' /></h3>
                  <p>Minha extensão para Chrome é para brincar com textos. Transforme em maiúsculas, minúsculas ou invertido. Poder <strong>aprender sobre o mundo das extensões Chrome</strong> e aplicar alguns conhecimentos sobre <strong>JavaScript</strong> foi meu maior premio nesse projeto!</p>
                </div>
              </Link>

              <p className='dizeres'>Desenvolvido por mim com paixão e dedicação, usando o <strong>Visual Studio Code.</strong><br></br>
                Este site é uma criação pessoal construída com as tecnologias <strong>React</strong> e <strong>Material-UI</strong>. <br></br> <strong>Espero que aprecie!</strong></p>
            </div>
          </div>
        </div>

      </Grid   >
      <Grid xs={2} id='grid'>
      </Grid>
    </Grid>
  );
}

export default App;
