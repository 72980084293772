import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useMediaQuery } from '@mui/material';

function Cv({ date, title, position, description, labels }) {

    const isMobile = useMediaQuery('(max-width: 767px)'); // Defina a largura máxima que define um dispositivo móvel

    return (
        <div className='experiences'>
            <div className='timeExperience'>
                <p>{date}</p>
            </div>

            <div className='infoExperience'>
                <h3>{title}</h3>
                <h4>{position}</h4>
                <p>{description}</p>
                <Stack  direction={isMobile ? 'column' : 'row'} spacing={1} id='stacksId' className='stacks'>
                    {labels.map((label, index) => (
                        <Chip key={index} label={label} color='primary' />
                    ))}
                </Stack>
            </div>
        </div>
    );
}

export default Cv;
